import React from "react"
import { connect } from "react-redux"
import { graphql } from "gatsby"
import { withStyles } from "@material-ui/styles"
import { Link } from "gatsby"
import { Buffer } from "buffer"

import Layout from "../../components/layout"
import {
  createCartHandler,
  refreshCart,
  updateCartProductQuantity,
} from "../../app/actions"
import {
  getCart,
  updateCart,
  removeProductFromCart,
} from "../../services/shopify"
import { isBrowser } from "../../helpers"
import styles from "./basket.styles"
import Breadcrumb from "../../components/Breadcrumb"

import { CoreHeadingBlock } from "../../components/blocks/CoreHeadingBlock"

const mapStateToProps = state => ({
  cartId: state.CartSettings.cartId,
  products: state.CartSettings.products,
})

const mapDispatchToProps = dispatch => ({
  createCart: () => dispatch(createCartHandler()),
  refreshCart: products => dispatch(refreshCart(products)),
  updateCartProductQuantity: (productId, quantity) =>
    dispatch(updateCartProductQuantity(productId, quantity)),
})

class CustomerCartPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ready: false,
      checkoutUrl: undefined,
      total: 0,
      locked: false,
      accepted: false,
      priceIsUpdating: false,
    }

    this.loadCart()

    this.combineWpDataWithShopifyData = this.combineWpDataWithShopifyData.bind(
      this
    )
    this.removeItemHandler = this.removeItemHandler.bind(this)
    this.updateQuantityHandler = this.updateQuantityHandler.bind(this)

    this.updateTimer = undefined
  }

  loadCart() {
    const { cartId, createCart } = this.props

    if (!cartId) {
      createCart()
    } else {
      this.loadCartHandler()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.cartId !== this.props.cartId && this.props.cartId) {
      this.loadCart()
    }
  }

  updateCartHandler() {
    this.setState({ locked: true })
    const p = this.props.products.map(product => {
      return {
        id: product.id,
        quantity: product.quantity,
      }
    })
    updateCart({
      cartId: this.props.cartId,
      products: p,
    }).then(res => {
      this.loadCartHandler()
    })
  }

  async loadCartHandler() {
    const { cartId, createCart } = this.props

    let res = await getCart(cartId)
    if (!res.data || !res.data.data || !res.data.data.id) {
      await createCart()
      return this.loadCartHandler()
    }

    const data = res.data.data.id
    await this.props.refreshCart
    this.setState({ locked: false })
    this.setState({ ready: true })

    // Add utm params to checkout url

    const params = {
      utm_source: "zest-store",
      utm_campaign: "zest-campaign",
      utm_medium: "website",
    }

    const utmParams = new URLSearchParams(params).toString()
    const checkoutUrl =
      data.checkoutUrl +
      (data.checkoutUrl.indexOf("?") === -1 ? "?" : "&") +
      utmParams

    this.setState({ checkoutUrl: checkoutUrl })
    this.setState({ total: Number(data.cost.totalAmount.amount) })
    this.setState({ priceIsUpdating: false })
  }

  removeItemHandler(productId) {
    const { cartId } = this.props
    this.setState({ locked: true })
    removeProductFromCart({ cartId, productId }).then(() => {
      this.loadCartHandler()
      this.props.refreshCart()
    })
  }

  updateQuantityHandler(productId, quantity) {
    this.setState({ quantity })
    this.setState({ priceIsUpdating: true })
    this.props.updateCartProductQuantity(productId, quantity)

    clearTimeout(this.updateTimer)
    this.updateTimer = setTimeout(() => {
      this.updateCartHandler()
    }, 1000)
  }

  combineWpDataWithShopifyData(id) {
    const { data } = this.props
    const base64Id = Buffer.from(id).toString("base64")
    return (
      data.allWpProduct &&
      data.allWpProduct.edges.find(edge => {
        if (!edge.node.ProductData.variants) return null

        return edge.node.ProductData.variants.find(
          variant => variant.variantIdBase64 === base64Id
        )
      })
    )
  }

  render() {
    const { ready, checkoutUrl, total } = this.state
    const { products = [] } = this.props
    const {
      combineWpDataWithShopifyData,
      removeItemHandler,
      updateQuantityHandler,
    } = this

    if (!ready) {
      return (
        <>
          <div className={this.props.classes.pageTitle}>
            <div className="wrapper">
              <CoreHeadingBlock
                attributes={{
                  textAlign: "left",
                  anchor: "",
                  className: "",
                  content: "Basket",
                  level: 1,
                  textColor: "primary-navy",
                  backgroundColor: "",
                  __typename: "WpCoreHeadingBlockAttributes",
                }}
                innerBlocks={[]}
              />
            </div>
          </div>
          <div className={this.props.classes.basketWrapper}>
            <p>Loading basket...</p>
          </div>
        </>
      )
    }

    if (products && products.length === 0) {
      return (
        <>
          <div className={this.props.classes.pageTitle}>
            <div className="wrapper">
              <CoreHeadingBlock
                attributes={{
                  textAlign: "left",
                  anchor: "",
                  className: "",
                  content: "Basket",
                  level: 1,
                  textColor: "primary-navy",
                  backgroundColor: "",
                  __typename: "WpCoreHeadingBlockAttributes",
                }}
                innerBlocks={[]}
              />
            </div>
          </div>
          <div className={this.props.classes.basketWrapper}>
            <p>No items in your basket yet</p>
          </div>
        </>
      )
    }

    return (
      <section>
        <div className={this.props.classes.pageTitle}>
          <div className="wrapper">
            <CoreHeadingBlock
              attributes={{
                textAlign: "left",
                anchor: "",
                className: "",
                content: "Basket",
                level: 1,
                textColor: "primary-navy",
                backgroundColor: "",
                __typename: "WpCoreHeadingBlockAttributes",
              }}
              innerBlocks={[]}
            />
          </div>
        </div>
        <div className={this.props.classes.basketWrapper}>
          <div className="column-titles">
            <div className="prod">Product</div>
            <div className="quantity">Quantity</div>
            <div className="price">Price</div>
          </div>
          {products &&
            products.length > 0 &&
            products.map(product => {
              const data = combineWpDataWithShopifyData(product.merchandise.id)
                .node
              return (
                <div
                  className={`product ${this.state.locked ? "opaque" : ""}`}
                  key={product.merchandise.id}
                >
                  <button
                    className="remove"
                    onClick={() => removeItemHandler(product.id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.502"
                      height="11.5"
                      viewBox="0 0 11.502 11.5"
                    >
                      <path
                        id="noun_cancel_2191250_1_"
                        data-name="noun_cancel_2191250 (1)"
                        d="M19.127,8.117a.833.833,0,0,0-1.18,0l-4.325,4.325L9.3,8.117A.834.834,0,0,0,8.117,9.3l4.325,4.325L8.117,17.947A.834.834,0,0,0,9.3,19.125L13.622,14.8l4.325,4.325a.834.834,0,1,0,1.182-1.177L14.8,13.622,19.127,9.3A.837.837,0,0,0,19.127,8.117Z"
                        transform="translate(-7.872 -7.872)"
                        fill="#a90011"
                      />
                    </svg>
                  </button>
                  <Link to={data.uri}>
                    {product.merchandise.image && (
                      <img
                        src={product.merchandise.image.src}
                        width={300}
                        alt={product.merchandise.title}
                      />
                    )}
                  </Link>
                  <div className="product-info">
                    <Link to={data.uri}>
                      <p className="title">
                        {product.merchandise.product.title}
                      </p>

                      {product.merchandise.title === "No Extras" ||
                      product.merchandise.title === "Default Title" ? (
                        ""
                      ) : (
                        <p>{product.merchandise.title}</p>
                      )}

                      <p>
                        SKU <span>{product.merchandise.sku}</span>
                      </p>
                    </Link>
                  </div>
                  <div className="quantity-control">
                    <button
                      disabled={this.state.locked}
                      onClick={() =>
                        updateQuantityHandler(
                          product.id,
                          Math.max(1, product.quantity - 1)
                        )
                      }
                    >
                      -
                    </button>
                    <input
                      type="text"
                      value={product.quantity}
                      min={1}
                      onChange={e =>
                        updateQuantityHandler(product.id, e.target.value)
                      }
                    />
                    <button
                      disabled={this.state.locked}
                      onClick={() =>
                        updateQuantityHandler(product.id, product.quantity + 1)
                      }
                    >
                      +
                    </button>
                  </div>
                  <p className="price">
                    <span>Price </span>&#163;
                    {(
                      product.merchandise.price.amount * product.quantity
                    ).toFixed(2)}
                  </p>
                </div>
              )
            })}
          {total && (
            <p
              className={`total ${
                this.state.priceIsUpdating ? "updating" : ""
              }`}
            >
              Total cost <span>&#163;{total.toFixed(2)}</span>
            </p>
          )}
          <p className="delivery">
            Delivery
            <span>
              <strong>FREE UK delivery*</strong> *Except Scottish Highlands and
              N. Ireland
            </span>
          </p>
          <label className="tac-accept">
            <input
              type="checkbox"
              onChange={e => this.setState({ accepted: e.target.checked })}
            />
            <span className="checkmark" />
            By placing this order, we assume you have read and accept our{" "}
            <Link to="/terms-conditions">Terms and Conditions</Link>
          </label>
          {this.state.accepted ? (
            <a className="checkout" href={checkoutUrl}>
              Proceed to Checkout
            </a>
          ) : (
            <a
              className="checkout disabled"
              href={checkoutUrl}
              onClick={e => e.preventDefault()}
            >
              Proceed to Checkout
            </a>
          )}
        </div>
      </section>
    )
  }
}

const ConnectedCustomerCartPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerCartPage)

const Page = ({ data }) => {
  if (!isBrowser) return null

  const StyledCartPage = withStyles(styles)(ConnectedCustomerCartPage)

  return (
    <Layout
      meta={{
        metaTitle: "Your Basket | Zest Wooden Outdoor Products",
        metaDescription:
          "Items in your basket – Your shopping basket – Proceed to check out to complete your order",
      }}
      title="Account"
    >
      <Breadcrumb
        type="page"
        ancestors={null}
        current={{
          title: "Basket",
          slug: "cart",
          uri: "/customer/cart",
        }}
      />
      <StyledCartPage data={data} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpProduct {
      edges {
        node {
          id
          title
          uri
          ProductData {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 300
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                )
              }
            }
            comparePrice
            description
            featuredImage
            options
            price
            images {
              image
              altText
            }
            title
            productIdBase64
            variants {
              inStock
              comparePrice
              image
              option1
              option2
              option3
              price
              quantity
              sku
              title
              weight
              variantIdBase64
            }
          }
        }
      }
    }
  }
`

export default Page
